<!-- =========================================================================================
  File Name: UserView.vue
  Description: User View page
========================================================================================== -->

<template>
  <div class="text-center">
    <vs-button
      v-if="token"
      :href="
        'https://duotalk.superlogica.net/clients/areadocliente?token=' + token
      "
      color="primary"
      icon="icon-eye"
      icon-pack="feather"
      >Acessar Painel Financeiro
    </vs-button>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      token: null,
    };
  },
  methods: {
    getSuperlogicaToken() {
      const acc = this.$store.state.acc.current_acc.id;
      this.$http({
        method: "GET",
        url: `/g/acc/plan/financial/sl-token`,
        params: {
          acc,
        },
      }).then((response) => {
        if (response.data.data.token) this.token = response.data.data.token;
      });
    },
  },
  mounted() {
    this.getSuperlogicaToken();
  },
};
</script>

<style>
body {
  background: none;
}
</style>